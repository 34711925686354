import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BetaHero from "../components/betahero"
import media from "../utils/mediaQueries"

const { se, desktop } = media

const SecondPage = () => (
  <Layout>
    <SEO title="Request Your Free Account" />
    <BetaHero />
    <Section>
      <FormWrapper>
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
        <iframe
          class="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shr6MsuLSqAJquUa5?backgroundColor=blue"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="2174"
        ></iframe>
      </FormWrapper>
    </Section>
  </Layout>
)

export default SecondPage

const FormWrapper = styled.div`
  margin: auto;
  position: absolute;
  z-index: 2;
  margin: auto;
  left: 0;
  right: 0;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);

  ${se &&
    se`
width: 95%;
top: -50px;
`}

  ${desktop &&
    desktop`
width: 800px;
top: -150px;
`}
`

const Section = styled.div`
  position: relative;
  background: white;
  width: 100%;
  min-height: 2300px;
`
