import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import H1 from "./typography/H1"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import InternalButton from "./layout/internalbutton"
import { textShadow } from "../utils/shadows"

const { se, desktop } = media

const BetaHero = () => (
  <BetaHeroWrapper>
    <BetaHeroContainer>
      <TextContainer>
        <StyledH1>Request Your Free Account</StyledH1>
        <StyledP>The only marketing solution you’ll ever need</StyledP>
      </TextContainer>
      <ImageContainer />
    </BetaHeroContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <polygon fill="white" points="0,100 100,0 100,100" />
    </svg>
  </BetaHeroWrapper>
)

export default BetaHero

const BetaHeroWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(87, 172, 45, 0.15);
  position: relative;
  overflow: hidden;
  ${se &&
    se`
   padding: 50px 0 50px 0;
  `}
  ${desktop &&
    desktop`
    height: 500px;
  `}

svg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10vw;
    /* set height to pixels if you want angle to change with screen width */
  }
`

const BetaHeroContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 0 20px;
    width: 100%;
    flex-direction: column;
  `}

  ${desktop &&
    desktop`
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 0;
  `}
`

const ImageContainer = styled.div`
  ${se &&
    se`
    width: 100%;
  `}

  ${desktop &&
    desktop`
    width: 48;
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
  `}

  ${desktop &&
    desktop`
    margin-bottom: 0px;
  `}
`

const StyledH1 = styled(H1)`
  text-align: center;
  ${textShadow()};

  ${se &&
    se`
font-size: 30px;
`}

  ${desktop &&
    desktop`
  margin-bottom: 15px;
  font-size: 37px;
  `}
`

const StyledP = styled(P)`
  text-align: center;

  ${se &&
    se`
  font-size: 18px;
`}

  ${desktop &&
    desktop`
  margin-bottom: 30px;
  font-size: 24px;
  `}
`
